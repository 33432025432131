import styles from "./App.module.scss";
import Header from "components/Header";
import WELCOME from "assets/images/nika.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useWindowDimensions } from "app/hooks";
import "react-lazy-load-image-component/src/effects/blur.css";
import HeroVideo from "components/HeroVideo";

const Campaign = () => {
  const { height, width } = useWindowDimensions();
  const sm = 576;
  return <div className={styles.layout}>
    <LazyLoadImage
      height={width < sm ? (width / 390) * 619 : height}
      width={width < sm ? width : (height / 619) * 390}
      src={WELCOME}
      effect="blur"
    ></LazyLoadImage>
    <div className={styles.content}>
      <div className={styles.title}>Flow art classes</div>
      <div className={styles.title}>Уроки флоу-арта</div>
      <div className={styles.desc}>
        Practice the art of harmonious movement of soul, body and mind using
        props.
        <br></br>
        You will learn to spin poi. You are welcome with any other props:
        fans, staff, double staff.
      </div>
  
      <div className={styles.desc}>
        Практика гармоничного движения души, тела и ума с использованием
        реквизита.  
      </div>
      <div className={styles.btncont}>
        <a
          style={{ display: "table-cell" }}
          target="_blank"
          href={"https://qrd.at/nika"}
          className={styles.btn}
        >
          Join telegram group for more info
        </a>
      </div>
    </div>
  </div>
}


function App() {

  return (
    <div className={styles.page}>
      <Header />
      <HeroVideo />
      <div style={{ width: '100vw', height: '100vh' }}>
        <Campaign />
      </div>

    </div>
  );
}

export default App;
