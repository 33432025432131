
// @ts-ignore
import mobileVideo from "./mobile.mp4";
// @ts-ignore

import desktopVideo from "./desktop.mp4";
// @ts-ignore
import tabletVideo from "./tablet.mp4";
import poster from "./poster_blurred.jpg";

import styles from "./index.module.scss";
import { useWindowDimensions } from "app/hooks";


const HeroVideo = () => {
    const getVideoSrc = (width: number) => {
        if (width >= 1080) return desktopVideo;
        if (width >= 720) return tabletVideo;
        return mobileVideo;
    };
    const { height, width } = useWindowDimensions();

    const src = getVideoSrc(width);
    return (
        <div className={styles.container}>
            <div className={styles.mouseContainer}>
                <div className={styles.chevron}></div>
                <div className={styles.chevron}></div>
                <div className={styles.chevron}></div>
            </div>
            <video
                poster={poster}
                autoPlay loop playsInline muted src={src} />
        </div>
    );
};

export default HeroVideo;