import styles from "./index.module.scss";
import LOGO from "assets/images/logo.svg";
import INSTA from "assets/images/Instagram.svg";
import YT from "assets/images/YouTube.svg";

const Header = () => {
  return (
    <div className={styles.page}>
      <div className={styles.leftCol}>
        <img src={LOGO} className={styles.logo}></img>
        <div className={styles.logotxt}>nikaflow</div>
      </div>
      <div className={styles.rightCol}>
        <img
          onClick={() =>
            window.open(
              "https://www.instagram.com/nikaflow.art/",
              "_blank"
            )
          }
          className={styles.miniIcon}
          src={INSTA}
        ></img>
        <img
          onClick={() =>
            window.open(
              "https://www.youtube.com/channel/UCJla1-ileXm8JebEMSBqvIA",
              "_blank"
            )
          }
          className={styles.miniIcon}
          src={YT}
        ></img>
      </div>
    </div>
  );
};

export default Header;
